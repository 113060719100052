var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"ml-2"},[_c('PageTitle',{staticClass:"ft font-weight-bold font-size-lg",attrs:{"text":"Branch Managers"}})],1),_c('div',{staticClass:"buttonText ml-1",attrs:{"id":"result"}},[_c('h4',{staticClass:"ft font-weight-bold font-size-sm",staticStyle:{"margin-top":"20px","margin-left":"10px"}},[_vm._v(" All Branch Managers ")])]),_c('v-row',{staticStyle:{"margin-top":"5px","margin-left":"1px","margin-right":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[[_c('div',[_c('v-data-table',{staticClass:"box-shadow-light ft font-weight-medium font-size-md",attrs:{"headers":_vm.headers,"items":_vm.managers,"item-key":"source","server-items-length":_vm.paginate.total,"options":_vm.options,"loading-text":"Loading... Please wait","loading":_vm.loading,"footer-props":{
              itemsPerPageOptions: [10, 20, 30]
            }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.branchId",fn:function(ref){
            var item = ref.item;
return [(item.branchId)?_c('v-chip',{attrs:{"color":"primary","small":"","label":""}},[_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v(_vm._s(item.branchId.name))])]):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v(_vm._s(_vm._f("timestamp")(item.createdAt,"Do MMM, YYYY")))])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.open('generate', 'users/details', item.id)}}},on),[_c('v-icon',{staticStyle:{"font-size":"14px"},attrs:{"color":"primary"}},[_vm._v("notifications_active")])],1)]}}],null,true)},[_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v("Set Notification Preferences")])])]}}])})],1)]],2)],1),_c('NotificationPreferenceBottomSheet',{attrs:{"state":_vm.dialog('generate'),"loading":_vm.isLoading,"details":_vm.userDetails},on:{"savePreference":_vm.savePreference,"actions":_vm.close}}),_c('v-snackbar',{attrs:{"timeout":4000,"top":"","color":_vm.status},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.message))]),_c('v-btn',{attrs:{"text":"","color":"white"},on:{"click":function($event){_vm.showSnackBar = false}}},[_vm._v("Close")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }