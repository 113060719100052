<template>
  <v-container fluid>
    <div class="ml-2">
      <PageTitle
        text="Branch Managers"
        class="ft font-weight-bold font-size-lg"
      />
    </div>
    <div class="buttonText ml-1" id="result">
      <h4
        style="margin-top: 20px; margin-left: 10px"
        class="ft font-weight-bold font-size-sm"
      >
        All Branch Managers
      </h4>
    </div>
    <v-row
      align="center"
      justify="center"
      style="margin-top: 5px; margin-left:1px; margin-right:10px"
    >
      <v-col cols="12" sm="12">
        <template>
          <div>
            <v-data-table
              :headers="headers"
              :items="managers"
              item-key="source"
              :server-items-length="paginate.total"
              :options.sync="options"
              loading-text="Loading... Please wait"
              :loading="loading"
              class="box-shadow-light ft font-weight-medium font-size-md"
              :footer-props="{
                itemsPerPageOptions: [10, 20, 30]
              }"
            >
              <template #item.branchId="{item}">
                <v-chip color="primary" small label v-if="item.branchId">
                  <span class="ft font-weight-medium font-size-sm">{{
                    item.branchId.name
                  }}</span>
                </v-chip>
              </template>
              <template #item.createdAt="{item}">
                <span class="ft font-weight-medium font-size-sm">{{
                  item.createdAt | timestamp("Do MMM, YYYY")
                }}</span>
              </template>
              <template #item.actions="{item}">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="open('generate', 'users/details', item.id)"
                      icon
                      v-on="on"
                    >
                      <v-icon color="primary" style="font-size: 14px;"
                        >notifications_active</v-icon
                      >
                    </v-btn>
                  </template>
                  <span class="ft font-weight-medium font-size-sm"
                    >Set Notification Preferences</span
                  >
                </v-tooltip>
              </template>
            </v-data-table>
          </div>
        </template>
      </v-col></v-row
    >
    <NotificationPreferenceBottomSheet
      :state="dialog('generate')"
      :loading="isLoading"
      @savePreference="savePreference"
      :details="userDetails"
      @actions="close"
    />
    <v-snackbar v-model="showSnackBar" :timeout="4000" top :color="status">
      <span class="white--text">{{ message }}</span>
      <v-btn text color="white" @click="showSnackBar = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import PageTitle from "../../../components/PageTitle";
import * as axios from "axios";
import moment from "moment";
import { timestamp } from "../../../filters/timestamp";
import DialogMixins from "../../../mixins/DialogMixins";
import { mapGetters } from "vuex";
export default {
  name: "BranchManagers",
  components: {
    PageTitle,
    NotificationPreferenceBottomSheet: () =>
      import(`./dialogs/NotificationPreferenceBottomSheet.vue`)
  },
  data() {
    return {
      progress: false,
      dialog1: false,
      formHasErrors: false,
      showSnackBar: false,
      dialogUpdate: false,
      phone: "",
      lng: "",
      lat: "",
      message: "",
      status: "",
      name: "",
      itemId: "",
      itemName: "",
      itemLat: "",
      itemLng: "",
      itemPhone: "",
      itemLocation: "",
      itemRegion: "",
      managers: [],
      dialogDelete: false,
      region: "",
      location: "",
      sourceInfo: {},
      itemObject: {
        name: null,
        location: null,
        phone: null
      },

      rules: {
        min: value => value.length >= 10 || "Must be 10 digits",
        requiredLoc: value =>
          !!value || value === null || "Location field is required.",
        requiredName: value => !!value || value === null || "Name is required.",
        requiredRegion: value =>
          !!value || value === null || "Region is Required.",
        requiredLat: value =>
          !!value || value === null || "Latitude is Required.",
        requiredLng: value =>
          !!value || value === null || "Longitude is Required."
      },
      loading: true,
      paginate: {
        total: 0,
        page: 1,
        limit: 3
      },
      options: {}
    };
  },
  mixins: [DialogMixins],
  computed: {
    ...mapGetters({
      userDetails: "users/getUserDetails",
      isLoading: "getIsLoading"
    }),
    headers() {
      return [
        {
          text: "",
          value: "",
          filterable: false,
          sortable: false
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name"
        },
        {
          text: "Branch",
          align: "start",
          sortable: false,
          value: "branchId"
        },
        { text: "Phone", value: "mobile" },
        { text: "Email", value: "email" },
        { text: "Date Enrolled", value: "createdAt" },
        { text: "Actions", value: "actions" }
      ];
    }
  },
  watch: {
    options: {
      handler(value) {
        const { page, itemsPerPage } = value;
        this.getManagers({ page, limit: itemsPerPage });
      },
      deep: true
    }
  },

  created() {
    this.getManagers({ page: 1, limit: 10 });
  },
  filters: {
    timestamp,
    formatDate(value) {
      return moment(value)
        .subtract(0, "days")
        .calendar();
    },
    firstLetterFunction(value) {
      return value.charAt(0).toUpperCase();
    }
  },

  methods: {
    savePreference(payload) {
      this.$store.dispatch("users/setNotificationPreferences", payload);
    },
    openDialog(value) {
      this.dialogUpdate = true;
      this.itemId = value.id;
      this.itemName = value.name;
      this.itemLocation = value.address;
      this.itemPhone = value.phoneNumber;
    },

    deleteFunction(item) {
      this.dialogDelete = true;
      this.sourceInfo = item;
    },

    deleteSource() {
      this.progress = true;

      setTimeout(() => {
        axios({
          method: "delete",
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`
          },
          url: `${process.env.VUE_APP_BASE}/sources/${this.sourceInfo.id}`
        })
          .then(response => {
            this.dialogDelete = false;
            if (response.status === 200) {
              this.status = "success";
              this.message = "Source has been deleted successfully.";
              this.showSnackBar = true;
              this.dialogUpdate = false;
              this.getSources(this.options.page, this.options.itemsPerPage);
            }
          })
          .catch(err => {
            if (err.response.status === 401) {
              this.$router.replace({ path: "/login" });
            }
          });
      }, 2000);
    },

    save() {
      this.progress = true;
      axios({
        method: "put",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        },
        url: `${process.env.VUE_APP_BASE}/sources/${this.itemId}`,
        data: {
          name: this.itemName,
          address: this.itemLocation,
          phoneNumber: this.itemPhone
        }
      })
        .then(response => {
          this.status = "success";
          this.message = response.data.message;
          this.showSnackBar = true;
          this.progress = false;
          this.dialogUpdate = false;
          this.getSources(this.options.page, this.options.itemsPerPage);
        })
        .catch(err => {
          if (err.response.status === 401) {
            this.$router.replace({ path: "/login" });
          } else {
            this.progress = false;
            this.status = "warning";
            this.showSnackBar = true;
            this.message = "Failed to update this branch.";
          }
        });
    },

    add() {
      if (this.name !== "" && this.location !== "" && this.phone !== "") {
        this.progress = true;
        setTimeout(() => {
          axios({
            method: "post",
            headers: {
              authorization: `Bearer ${localStorage.getItem("token")}`
            },
            url: `${process.env.VUE_APP_BASE}/sources`,
            data: {
              name: this.name,
              phoneNumber: this.phone,
              address: this.location
            }
          })
            .then(response => {
              this.getSources(this.options.page, this.options.itemsPerPage);
              this.dialog1 = false;
              if (response.status === 201) {
                this.status = "success";
                this.message = response.data.message;
                this.showSnackBar = true;
                this.name = this.itemObject.name;
                this.location = this.itemObject.location;
                this.phone = this.itemObject.phone;
              }
            })
            .catch(err => {
              if (err.response.status === 401) {
                this.$router.replace({ path: "/login" });
              } else {
                this.progress = false;
                this.dialog1 = true;
                this.status = "warning";
                this.message = "Failed to add a new source.";
              }
            });
        }, 1000);
      } else {
        this.showSnackBar = true;
        this.status = "warning";
        this.message = "All fields required.";
      }
    },

    getManagers({ page, limit }) {
      this.progress = true;
      this.loading = true;
      axios({
        method: "get",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        },
        url: `${process.env.VUE_APP_BASE}/user/branch/managers?isPaginated=true&page=${page}&limit=${limit}`
      })
        .then(response => {
          if (response.status === 200) {
            this.progress = false;
            this.loading = false;

            const { docs, page, limit, total } = response.data.paginateObj;
            this.managers = docs;
            this.paginate = { page, limit, total };
          }
        })
        .catch(err => {
          this.loading = false;
          this.progress = false;
          if (err.statusCode === 401) {
            this.$router.replace({ path: "/login" });
          } else {
            localStorage.clear();
          }
        });
    }
  }
};
</script>

<style scoped>
.button {
  background-color: #04a9f3; /* Green */
  border: none;
  color: white;
  padding: 1px 4px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-right: 40px;
  cursor: pointer;
}
#result .v-btn {
  min-width: 65px;
  width: 40px;
}
/* removes grey hover effect */
#result .v-btn::before {
  background-color: transparent;
}

/* adds icon scale hover effect */
#result .v-btn i:hover {
  transform: scale(1.15);
}

.buttonText {
  display: flex;
  flex-direction: row;
}
</style>
